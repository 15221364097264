import { useState } from "react";
import HeaderGrey from '../../../Components/Headers/HeaderGrey/HeaderGrey';
import ButtonLarge from "../../../Components/Buttons/ButtonLarge/ButtonLarge";
import InputTextLarge from "../../../Components/Form/InputTextLarge/InputTextLarge";
import ImageRegistration from '../img/visuel-registration.png';
import PopIn from '../../../Components/PopIn/PopIn';
import style from '../LoginPages-styles.css';
import ImagePopinSuccess from "../../../Components/PopIn/img/compte-success.png";
import { forgottenPassword } from "../../../Services/ConnectionService.js";




const ForgotPassword = () => {
  const [isPopInOpen, setPopInOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError("");
  };
  const handleOpenPopIn = () => {
    setPopInOpen(true);
  };

  const handleClosePopIn = () => {
    setPopInOpen(false);
  };

  const handleClic = async () => { // Add handler for login button
    const emailPattern = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (email.trim() === "" || !emailPattern.test(email)) {
      setEmailError("Veuillez saisir une adresse email valide");
      return;
    } else {
      setEmailError("");
    }


    const result = await forgottenPassword(email);
    if (!result.success) {
      setEmailError("Une erreur s'est produite, veuillez réessayer.");
    }
    handleOpenPopIn();
  };

  return (
    <div className="Registration" >

      <HeaderGrey showLoginButton={false} showSignupButton={true} />
      <div className="containerRegistration">

        <div className="containerFormRegistration">
          <h1 className="">Mot de passe oublié</h1>

          <div className="imputs">

            <InputTextLarge
              type="text"
              textPlaceHolder="Entrer votre email"
              onChange={handleEmailChange}
              hasError={emailError && emailError.length > 0}
              typeError={emailError}

            />


          </div>

          <div className="containerBtnLink">
            <ButtonLarge
              text="Valider"
              backgroundColor="var(--blue-geogaming)"
              color="var(--blanc)"
              hideBorder="false"
              alignSelf='stretch'
              hideIcon="false"
              onClick={handleClic} // Add onClick handler here

            />
            <PopIn
              isOpen={isPopInOpen}
              onClose={handleClosePopIn}
              imageUrl={ImagePopinSuccess}
              title="Email envoyé"
              content="Un email a été envoyé avec les nouveaux identifiants."
              buttonText="Retourner à la page de connexion"
              buttonLink="/connexion"
              hideInput='true'

            />


          </div>
        </div>
        <div className="visuelRegistration">
          <img src={ImageRegistration} />
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;