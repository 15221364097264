import styles from '../MyJourneys-styles.css';
import EmptyTableStyles from './EmptyTableStyles.css';
import EditorPagesStyles from '../../EditorPages-styles.css';
import ButtonMedium from '../../../../Components/Buttons/ButtonMedium/ButtonMedium';
import MoreIcon from '../../../../Components/Icons/more.svg';
import nonJourney from'./nonJourney.png';

const EmptyTable= () => {
    return (
        <div className="containerChild-1">
                <img className="img-empty-table" src={nonJourney}/>
                <div className="containerTextEmptyImage">
                    <h4>Vous n’avez pas encore de scénario</h4>
                    <ButtonMedium
                    text="Ajouter un parcours"
                    backgroundColor="var(--blue-geogaming)"
                    color="var(--blanc)"
                    hideBorder="false"
                    link="/typesdeparcours"
                    icon="icon-more"
                    />

                </div>

        </div>
    );
};
export default EmptyTable;