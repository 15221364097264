import React, { useState } from "react";
import HeaderBlue from "../../../Components/Headers/HeaderBlue/HeaderBlue";
import Card from "../../../Components/Card/Card";
import styles from "./KindOfJourney-styles.css";
import ImgParcoursGuide from "./img/parcours-guide.png";
import ImgCourseOrientation from "./img/coursedorientation.png";
import ImgParcoursAvance from "./img/parcoursavance.png";
import PopIn from "../../../Components/PopIn/PopIn";
import ImagePopinSuccess from "../../../Components/PopIn/img/compte-success.png";

const KindOfJourney = () => {
  const [isPopInOpen, setPopInOpen] = useState(false);

  const handleOpenPopIn = () => {
    setPopInOpen(true);
  };

  const handleClosePopIn = () => {
    setPopInOpen(false);
  };
  return (
    <div className="containerEditor">
      <HeaderBlue />
      <div className="containerChild">
        <h2>Lancez-vous, créez votre parcours</h2>
        <div className="containerChild-1">
          <div className="containerKindOfJourney">
            <h3 className="title">Choisissez votre template</h3>
            <div className="containerCards">
              <Card
                title="Parcours guidé"
                paragraphe={
                  <React.Fragment>
                    <li>Le joueur est guidé </li>
                    <li>Recommandé pour les débutants </li>
                    <li>Idéal pour les visites guidées, ou parcours quizz.</li>
                  </React.Fragment>
                }
                imgSrc={ImgParcoursGuide}
                textBtn="Commencer"
              />
              <Card
                title="Course d'orientation"
                imgSrc={ImgCourseOrientation}
                paragraphe={
                  <React.Fragment>
                    <li>Le joueur doit trouver les étapes </li>
                    <li>Plus difficile mais plus challenging ! </li>
                    <li>Idéal pour un rallye, une course d'orientation.</li>
                  </React.Fragment>
                }
                textBtn="Commencer"
              />
              <Card
                title="Parcours avancé"
                imgSrc={ImgParcoursAvance}
                paragraphe={
                  <React.Fragment>
                    <li>
                      Plusieurs scénarios possibles pour l’utilisateur en fonction de ses choix
                    </li>
                    <li>Paramétrage plus difficile, conseillé aux rédacteurs avertis ! </li>
                  </React.Fragment>
                }
                textBtn="Commencer"
              />
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default KindOfJourney;
