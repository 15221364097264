import React from 'react';
import './IconNumber-styles.css';

const IconNumber = ({ text, iconName, showBorderRight }) => {
  const iconNumberContainerStyle = {
    borderRight: showBorderRight ? 'none' : '1px solid var(--line, #CCC)',
  };

  return (
    <div className="iconNumberContainer" style={iconNumberContainerStyle}>
      <p>{text}</p>
      <span className={`${iconName} iconStyle`}></span>
    </div>
  );
};

export default IconNumber;
