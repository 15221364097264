import React from 'react';
import './PopIn.css';
import { PopInConfig } from './PopInConfig';
import PictoExit from './img/picto-exit.svg';
import ButtonMedium from '../../Components/Buttons/ButtonMedium/ButtonMedium';
import InputTextBtnLarge from"../../Components/Form/InputTextBtnLarge/InputTextBtnLarge";

const PopIn = ({ isOpen, onClose, imageUrl, title, content, hideImage, commentaire, buttonText, buttonLink, hideBtn, hideContent, hideCommentaire, inputTextPlaceHolder, InputtextBtn, hideInput }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="popin-overlay">
      <div className="popin-content">
        <div className="containerPopInBtnExit">
          <button className="pictoExitButton" onClick={onClose}>
            <img className="pictoExitImage" src={PictoExit} alt="Picto Exit" />
          </button>
        </div>
        <div className="container-pop-in">
          {!hideImage && imageUrl && <img src={imageUrl} alt="Image Pop-in" />}
          {title && <h3 className="titlePopIn">{title}</h3>}
          <div className="container-content-pop-in">
            {!hideContent && content && <p className="contentPopIn">{content}</p>}
            {!hideCommentaire && commentaire && <p className="commentaire">{commentaire}</p>}
          </div>
          {!hideBtn && <ButtonMedium
            text={buttonText || ""}
            backgroundColor= "var(--blue-geogaming)"
            color= "var(--blanc)"
            hideBorder= 'false'
            hideIcon= 'false'
            link={buttonLink || ""}
          />}
          {!hideInput && <InputTextBtnLarge
          textPlaceHolder={inputTextPlaceHolder}
          textBtn={InputtextBtn}
          />}
        </div>
      </div>
    </div>
  );
};
export default PopIn;