import React from 'react';
import styles from './InputTextBtnLarge-styles.css';
import { inputConfig } from '../inputConfig';
import ButtonLarge from '../../Buttons/ButtonLarge/ButtonLarge';

const InputTextBtnLarge = ({ textPlaceHolder, value, onChange,textBtn }) => {
 
    return (
      <div className="containerInputTextBtnLarge">
       <form>
        <input type="text" placeholder={textPlaceHolder} value={value}
      onChange={onChange} id={textPlaceHolder} className="inputTextBtnLarge"/>
       </form>
       <div className="BtnInput">
          <ButtonLarge 
          text={textBtn}
          backgroundColor="var(--blue-geogaming)"
          color="var(--blanc)"
          hideBorder="false"
          link="/typesdeparcours"
          hideIcon="true"

          />
        </div>
      </div>
    );
  };
  
  export default InputTextBtnLarge;

  