import React, { useState } from "react";
import styles from "./Card-styles.css";
import ButtonMedium from "../Buttons/ButtonMedium/ButtonMedium";
import PopIn from "../../Components/PopIn/PopIn";

const Card = ({ imgSrc, textBtn, linkBtn, title, paragraphe }) => {
  const [isPopInOpen, setPopInOpen] = useState(false);

  const handleOpenPopIn = () => {
    setPopInOpen(true);
  };

  const handleClosePopIn = () => {
    setPopInOpen(false);
  };

  return (
    <div className="containerCard">
      <div className="containerImgCard">
        <img src={imgSrc} alt="Card Image" />
      </div>
      <div className="containerTitleParagraphe">
        <h4>{title}</h4>
        <p>{paragraphe}</p>
        <ButtonMedium
          text={textBtn}
          backgroundColor="var(--blue-geogaming)"
          color="var(--blanc)"
          hideBorder="false"
          link={linkBtn}
          hideIcon="false"
          onClick={handleOpenPopIn}
        />
      </div>
      <PopIn
        isOpen={isPopInOpen}
        onClose={handleClosePopIn}
        title={<React.Fragment><div>Comment s’appellera votre </div> <div> nouveau sénario</div> </React.Fragment>}
        content="Contenu de la PopIn"
        commentaire="Commentaire de la PopIn"
        hideImage="false"
        hideContent="true"
        hideBtn='true'
        hideCommentaire="true"
        inputTextPlaceHolder="Entrez le titre de votre sénario"
        InputtextBtn="Valider"
      />
    </div>
  );
};

export default Card;
