import React, { useState } from 'react';
import styles from './LogoV2-styles.css';
import LogoImageDefault from './img/logoV2.svg';
import LogoImageHover from './img/logoV2hover.svg';
import { NavLink } from 'react-router-dom';

const LogoV2 = ({ text, color, showIcon, link }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div className= {`logoV2 ${isHovered ? 'hovered' : ''}`}>
      <NavLink
        className="whiteLogoMaster"
        to={link}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
    
          <img src={LogoImageDefault} alt="Logo" />
          <img src={LogoImageHover} alt="Logo Hover" className="hoverImage" />
          <div className="geogamingV2">Geogaming</div>
    
      </NavLink>
    </div>
  );
};

export default LogoV2;
