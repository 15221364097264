import React from 'react';
import styles from './PopOver-styles.css';
import LinkComponent from '../Buttons/Link/LinkComponent';

const PopOver = ({ text1, text2, text3, text4, url1, url2, url3, url4, icon1, icon2, icon3, icon4, left, right, top, hideIcon }) => {
  const PopOverStyle = {
    left: left,
    right: right,
    top: top,
  };
  return (
    <div className="popover" style={PopOverStyle}>
      <ul className="link-list">
        <li className="link-item">
          <LinkComponent
            text={text1}
            link={url1}
            icon={hideIcon ? "" : icon1}
          />
        </li>
        <li className="link-item">
          <LinkComponent
            text={text2}
            link={url2}
            icon={hideIcon ? "" : icon2}
          />
        </li>
        <li className="link-item">
          <LinkComponent
            text={text3}
            link={url3}
            icon={hideIcon ? "" : icon3}
          />
        </li>
        <li className="link-item">
          <LinkComponent
            text={text4}
            link={url4}
            icon={hideIcon ? "" : icon4}
          />
        </li>
      </ul>
    </div>
  );
};

export default PopOver;
