import { useState } from "react";
import HeaderGrey from 'Components/Headers/HeaderGrey/HeaderGrey';
import ButtonLarge from "Components/Buttons/ButtonLarge/ButtonLarge";
import LinkComponent from "Components/Buttons/Link/LinkComponent";
import InputTextLarge from "Components/Form/InputTextLarge/InputTextLarge";
import ImageRegistration from '../img/visuel-registration.png';
import style from '../LoginPages-styles.css';
import { connectUser, googleLog } from "Services/ConnectionService.js";
import { GoogleLogin } from '@react-oauth/google';




const Connection = () => {


  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [formError, setFormError] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError("");
    setFormError("");
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordError("");
    setFormError("");
  };

  const handleLogin = async () => {
    const emailPattern = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (email.trim() === "" || !emailPattern.test(email)) {
      setEmailError("Veuillez saisir une adresse email valide");
      return;
    } else {
      setEmailError("");
    }

    if (password.trim() === "") {
      setPasswordError("Le champ mot de passe est requis");
      return;
    }

    const result = await connectUser(email, password);
    if (!result.success) {
      setFormError(result.message);
    } else {
      window.location.href = `https://edit.geo-gaming.com/login/ListScenario.php?keyUser=${result.keyUser}`;
    }
  };

  return (
    <div className="Registration">
      <HeaderGrey showLoginButton={false} showSignupButton={true} />
      <div className="containerRegistration">
        <div className="containerFormRegistration">
          <h1 className="">Se connecter</h1>
          <div className="btnGoogle">
            <GoogleLogin
              onSuccess={async credentialResponse => {
                const credential = credentialResponse.credential;
                googleLog(credential, this);

              }}
              onError={() => {
                console.log('Login Failed');
                setFormError("Erreur lors de la connexion Google, veuillez réessayer.");
              }}
              useOneTap
            />
          </div>

          <div className="ou">
            <div className="ouChild" />
            <div >Ou</div>
            <div className="ouChild" />
          </div>
          <div className="imputs">
            <InputTextLarge
              type="text"
              name="username"
              textPlaceHolder="Email"
              value={email}
              onChange={handleEmailChange}
              hasError={emailError && emailError.length > 0}
              typeError={emailError}
            />
            <InputTextLarge
              name="password"
              type="password"
              textPlaceHolder="Mot de passe"
              value={password}
              onChange={handlePasswordChange}
              hasError={passwordError && passwordError.length > 0}
              typeError={passwordError}
            />

          </div>

          <div className="containerBtnLink">
            <ButtonLarge
              text="Connexion"
              backgroundColor="var(--blue-geogaming)"
              color="var(--blanc)"
              hideBorder="false"
              alignSelf='stretch'
              hideIcon="false"
              link='/Connexion'
              onClick={handleLogin}
            />
            {formError && <span className="error">{formError}</span>}

            <div className="linkWhiteWrapper">
              <LinkComponent
                text="Mot de passe oublié"
                color="var(--blanc)"
                hideIcon="false"
                link='/connexion/motdepasseoublie'
              />
            </div>
          </div>
        </div>
        <div className="visuelConnection">
          <img src={ImageRegistration} />
        </div>
      </div>
    </div>
  );
};

export default Connection;
