import styles from'./Tag-styles.css';
const Tag = ({ text, color }) => {

    const tagStyle = {
color:color,
    };
    return (
        <div className="tag" style={tagStyle}>
            <p>{text}</p>
      </div>
    );
  };
  
  export default Tag;
  