import React from 'react';
import styles from'./LogoV1-styles.css';
import logo from'../logo.png';
import {NavLink} from'react-router-dom';
import { logoConfig } from '../logoConfig';

const Logo= ({ text, color, showIcon, link }) => {
    return(
        <div className="logo">
        <NavLink className={'whiteLogoMaster'} to={link}>
         
            <img className="imgLogo" src={logo}/>
            <div className="geogamingWrapper">
              <div className="geogaming">Geogaming</div>
            </div>
         
        </NavLink>
      </div>


    )}
    export default Logo;