import { useLocation } from 'react-router-dom';
import { useState } from "react";
import HeaderGrey from 'Components/Headers/HeaderGrey/HeaderGrey';
import ButtonLarge from "Components/Buttons/ButtonLarge/ButtonLarge";
import InputTextLarge from "Components/Form/InputTextLarge/InputTextLarge";
import ImageRegistration from '../img/visuel-registration.png';
import PopIn from 'Components/PopIn/PopIn';
import ImagePopinSuccess from "Components/PopIn/img/compte-success.png";
import ImagePopinFail from "Components/PopIn/img/fail-img.gif";
import style from '../LoginPages-styles.css';
import { registerUser, submitToHubSpotWithTracking } from "Services/RegistrationService.js";
import { googleLog } from "Services/ConnectionService.js";
import { GoogleLogin } from '@react-oauth/google';
import LinkComponent from "Components/Buttons/Link/LinkComponent";


const Registration = () => {

  const [isPopInOpen, setPopInOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [isSuccess, setSuccess] = useState(false);
  const [isFailure, setFailure] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [formError, setFormError] = useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const trackingId = searchParams.get('trackingId'); // Obtenez la valeur de trackingId de l'URL




  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError("");
  };

  const handleOpenPopIn = () => {
    setPopInOpen(true);
  };

  const handleClosePopIn = () => {
    setSuccess(false);
    setFailure(false);
    setPopInOpen(false);
  };

  const handleSuccess = () => {
    setSuccess(true);
    handleOpenPopIn();
  };

  const handleFailure = () => {
    setFailure(true);
    handleOpenPopIn();
  };

  const handleSubmit = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (email.trim() === "" || !emailRegex.test(email)) {
      setEmailError("Veuillez entrer un email valide");
      return;
    }

    const result = await registerUser(email);
    handleOpenPopIn();

    if (!result.success) {
      setFormError(result.message);
      handleFailure();
    } else {
      handleSuccess();
      await submitToHubSpotWithTracking(email, trackingId);


    }
  };




  return (
    <div className="Registration" >

      <HeaderGrey showLoginButton={true} showSignupButton={false} />
      <div className="containerRegistration">

        <div className="containerFormRegistration">
          <h1 className="">S’inscrire</h1>
          <div className="btnGoogle">
            <GoogleLogin
              onSuccess={async credentialResponse => {
                const credential = credentialResponse.credential;
                googleLog(credential, this);

              }}
              onError={() => {
                console.log('Login Failed');
                setFormError("Erreur lors de la connexion Google, veuillez réessayer.");
              }}
              useOneTap
            />
          </div>

          <div className="ou">
            <div className="ouChild" />
            <div >Ou</div>
            <div className="ouChild" />
          </div>
          <div className="imputs">
            <InputTextLarge
              name="username"
              type="text"
              textPlaceHolder="Email"
              value={email}
              onChange={handleEmailChange}
              hasError={emailError && emailError.length > 0}
              typeError={emailError}
            />


          </div>
          <div className="politiqueConfidentialit">
            <div className="enCrantUn">
              En créant un compte, j'accepte la politique de confidentialité et
              les <LinkComponent
                link="https://edit.geo-gaming.com/policies/"
                text="Conditions d'Utilisation de Geogaming"
                color="var(--bleu-green)"
                hideIcon="false"
              />
            </div>
          </div>

          <div className="containerBtnLink">
            <ButtonLarge
              text="Créer mon compte"
              backgroundColor="var(--blue-geogaming)"
              color="var(--blanc)"
              hideBorder="false"
              alignSelf='stretch'
              hideIcon="false"
              link=''
              onClick={async () => {
                handleSubmit();
              }}

            />
            {formError && <span className="error">{formError}</span>}

            {isSuccess && (
              <PopIn
                isOpen={isPopInOpen}
                onClose={handleClosePopIn}
                imageUrl={ImagePopinSuccess}
                title="Confirme ton compte"
                content="Cliquez sur le lien dans le mail qui vous a été envoyé pour confimer votre compte."
                commentaire="Pas de mail de reçu dans votre messagerie ? Renvoyez le mail"
                hideBtn="true"
                hideInput="true"
              />
            )}

            {isFailure && (
              <PopIn
                isOpen={isPopInOpen}
                onClose={handleClosePopIn}
                imageUrl={ImagePopinFail}
                title="Ouups, il y a eu une erreur"
                content="Le serveur ne semble pas répondre"
                commentaire="Veuillez recommencer l'action."
                hideBtn="false"
                hideInput="true"
              />
            )}

          </div>
        </div>
        <div className="visuelRegistration">
          <img src={ImageRegistration} />
        </div>
      </div>
    </div>
  );
};

export default Registration;