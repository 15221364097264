import React from 'react';
import IconNumberStyles from './State-styles.css';

const State = ({ text, color }) => {
  const stateStyle = {
    backgroundColor: color,
  };

  return (
    <div className="stateContainer">
      <div className="containerRond">
        <div className="rond" style={stateStyle}></div>
        </div>
      <p>{text}</p>
    </div>
  );
};

export default State;
