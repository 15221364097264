import React from 'react';
import styles from './ButtonLarge-styles.css';
import { buttonConfig } from '../buttonConfig';
import { NavLink } from 'react-router-dom';
import googleIcon from '../../Icons/Google.svg';

const ButtonLarge = ({ text, color, backgroundColor, link, hideBorder, alignSelf, borderRadius, hideIcon , onClick, icon }) => {
  const buttonStyle = {
    color: color,
    backgroundColor: backgroundColor,
    border: hideBorder ? 'none' : '', // Masque la bordure si hideBorder est vrai
    borderRadius: borderRadius,
  };

  const contenairButtonStyle = {
    alignSelf: alignSelf,
  };


  return (
    <NavLink className={'containerBtn'} onClick={onClick} to={link} style={contenairButtonStyle}>
      <div className={`masterBtnLarge ${styles.button}`} style={buttonStyle}>
      {!hideIcon && <p className={`icon ${icon} iconBtn`}></p>}
        <div className={styles.text}>{text}</div>
      </div>
    </NavLink>
  );
};

export default ButtonLarge;
