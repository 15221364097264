import { API_URL } from "Utils/config.js";

// RegistrationService.js
export async function registerUser(email) {

  const url = `${API_URL}/users/createScenaristTracking/${email}&`;
  const response = await fetch(url);

  if (!response.ok) {
    return { success: false, message: "Une erreur s'est produite lors de la création du compte" };
  }

  const reponseServeur = await response.text();
  console.log(reponseServeur);

  if (reponseServeur !== "OK") {
    return { success: false, message: "Le serveur a renvoyé une réponse invalide" };
  }

  return { success: true };
}


// Créez une fonction qui peut être appelée avec les paramètres corrects basés sur trackingId
export async function submitToHubSpotWithTracking(email, trackingId) {
  let portalId = '8726170';
  let formId = 'b7a2c311-6bd2-410b-a6ec-d0480496bf7d';

  switch (trackingId) {
    case 'test':
      portalId = '8726170';
      formId = 'b7a2c311-6bd2-410b-a6ec-d0480496bf7d';
      break;
    case 'trackingValue2':
      portalId = 'specificPortalId2';
      formId = 'specificFormId2';
      break;
    // ... et ainsi de suite pour d'autres valeurs ...
  }

  // Ensuite, utilisez ces ID pour appeler submitToHubSpot
  await submitToHubSpot(email, portalId, formId);
};

async function submitToHubSpot(email, portalId, formId) {
  const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;

  const data = {
    fields: [
      {
        name: "email",
        value: email
      }
    ],
    context: {
      pageUri: window.location.href,
      pageName: document.title
    }
  };

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      console.log('Submitted to HubSpot');
      // Vous pouvez effectuer d'autres actions en cas de succès ici
    } else {
      console.error('Failed to submit to HubSpot');
      // Gérer l'échec de la soumission ici
    }
  } catch (error) {
    console.error('Error submitting to HubSpot', error);
    // Gérer les erreurs de réseau ou autres erreurs inattendues ici
  }
}