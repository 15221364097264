import React from 'react';
import styles from "./HeaderGrey-styles.css";
import ButtonMedium from "../../Buttons/ButtonMedium/ButtonMedium";
import Logo from "../../Logo/LogoV1/LogoV1";
import LinkComponent from "../../Buttons/Link/LinkComponent";

export default function HeaderGrey({ showLoginButton, showSignupButton }) {
  return (
    <div className="header">
      <Logo link="/" />
      {showLoginButton && (
        <ButtonMedium
          text="Connexion"
          color="var(--color)"
          showIcon={false}
          hideIcon="false"
          link="/connexion"
        />
      )}
      {showSignupButton && (
        <ButtonMedium
          text="S'inscrire"
          color="var(--color)"
          showIcon={false}
          hideIcon="false"
          link="/inscription"
        />
      )}
    </div>
  );
}
