import React from 'react';
import styles from './Link-styles.css';
import { buttonConfig } from '../buttonConfig';
import { NavLink } from 'react-router-dom';

const LinkComponent = ({ text, color, link, icon, hideIcon }) => {
  const linkStyle = {
    color: color,
  };

  return (
    <NavLink className={'containerLink'} to={link}>
      <div className={`link ${styles.button} containerchild`} >
      {!hideIcon &&<p className={`icon ${icon} iconLink`}></p>}
       <p className={'linkComponent'}style={linkStyle}>{text}</p>
      </div>
    </NavLink>
  );
};

export default LinkComponent;
