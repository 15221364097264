import React, { useState } from 'react';
import styles from "./MenuHorizontal.css";
import { NavLink } from 'react-router-dom';

const MenuHorizontal = ({ text, color, link }) => {
    const textMenuHorizontal = {
        color: color,
    };
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <NavLink
            to={link}
            className={`itemMenuHorizontal ${isHovered ? 'hovered' : ''}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            activeClassName="active"
        >
            <div style={textMenuHorizontal}>{text}</div>
            {(isHovered || window.location.pathname === link) && <div className="rectangleMenuHorizontal"></div>}
        </NavLink>
    );
};

export default MenuHorizontal;
