import styles from '../MyJourneys-styles.css';
import EditorPagesStyles from '../../EditorPages-styles.css';
import CompassIconDetailed from"../../../../Components/Icons/compass-detailed.svg";
import Tag from"../../../../Components/Tags/Tag/Tag";
import IconNumber from"../../../../Components/Tags/IconNumber/IconNumber";
import State from"../../../../Components/Tags/State/State";
import ComplementaryActions from"../../../../Components/Buttons/ComplementaryActions/ComplementaryActions";
import ButtonSmall from '../../../../Components/Buttons/ButtonSmall/ButtonSmall';
import ButtonMedium from '../../../../Components/Buttons/ButtonMedium/ButtonMedium';
import MoreIcon from '../../../../Components/Icons/more.svg';

const FiledTable= () => {
    return (
        <div className="containerChild-1">
        <div className="tableLineBtn">
        <ButtonMedium
          text="Ajouter un parcours"
          backgroundColor="var(--blue-geogaming)"
          color="var(--blanc)"
          hideBorder="false"
          link=""
          icon="icon-more"
        />
      </div>
        <table className="tableEditor1">
             <tbody>
                <tr>
                <td className="Icontext">
                    <div className="iconJourney">
                        <img src={CompassIconDetailed}/>
                    </div>
                    <p>Titre du sénario</p>
                    <Tag
                    text="Paris"
                    color="var(--blanc)"
                    />
                </td>
                <td className="statistique">
                    <IconNumber
                    text="4,5"
                    iconName="icon-star"
                    />
                    <IconNumber
                    text="60"
                    iconName="icon-peoples"
                  
                    />
                     <IconNumber
                    text="600"
                    iconName="icon-compass"
                    showBorderRight="false"
                    />
                 </td>  
                <td> 
                    <State
                    text="Privé"
                    color="var(--prive)"
                    />
                </td>
                <td>
                <ButtonSmall
                    text="Publier"
                    link=""
                    hideIcon="false"
            />
                </td>
                <td>
                <ComplementaryActions
                />
                </td>
                </tr>
                </tbody>
            </table>
            </div>
    );
};
export default FiledTable;