import React, { useState } from 'react';
import './MenuMonProfil-styles.css';
import PopOver from"../../../PopOver/PopOver";

const MenuMonProfil = () => {
  const [isOpen, setIsOpen] = useState(false);

  const togglePopover = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="containerMonprofil">
      <button className="icon-button" onClick={togglePopover}>
        <p className="icon-avatar avatar-white"></p>
      </button>
      {isOpen && (
        <PopOver
        text1="Mon Profil"
        icon1="icon-avatar"
        text2="Mes Factures"
        icon2="icon-invoice"
        text3="Mes Paiements"
        icon3="icon-payment"
        text4="Déconnexion"
        icon4="icon-logout"
        right="0"
     
        />
        
      )}
    </div>
  );
};

export default MenuMonProfil;
