import React, { useState } from 'react';
import './ComplementaryActions-styles.css';
import PopOver from"../../PopOver/PopOver";

const ComplementaryActions = () => {
  const [isOpen, setIsOpen] = useState(false);

  const togglePopover = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="containerComplementaryActions">
      <button className="icon-button" onClick={togglePopover}>
        <span class="icon-threeDots"></span>
      </button>
      {isOpen && (
        <PopOver
        text1="Partager le sénario"
        icon1="icon-calendar"
        text2="Storyboard"
        icon2="icon-checklist"
        text3="Simuler le sénario"
        icon3="icon-play2"
        text4="Supprimer"
        icon4="icon-delete"/>
        
      )}
    </div>
  );
};

export default ComplementaryActions;
