import React from 'react';
import HeaderBlue from '../../../Components/Headers/HeaderBlue/HeaderBlue';
import styles from './MyJourneys-styles.css';
import EditorPagesStyles from '../EditorPages-styles.css';
import FiledTable from'./FiledTable/FiledTable';
import EmptyTable from'./EmptyTable/EmptyTable';


const MyJourneys = () => {
  return (
    <div className="containerEditor">
      <HeaderBlue />
      <div className="containerChild">
        <h2>Mes parcours</h2>
          <EmptyTable/>
          <FiledTable/>
      </div>
    </div>
  );
};

export default MyJourneys;
