import React from 'react';
import styles from './ButtonSmall-styles.css';
import { buttonConfig } from '../buttonConfig';
import { NavLink } from 'react-router-dom';

const ButtonSmall = ({ text, color, backgroundColor, link, hideBorder, alignSelf, borderRadius, hideIcon, onClick, iconSrc}) => {
  const buttonStyle = {
    color: color,
    backgroundColor: backgroundColor,
    border: hideBorder ? 'none' : '', // Masque la bordure si hideBorder est vrai
    borderRadius: borderRadius,
  };

  const contenairButtonStyle = {
    alignSelf: alignSelf,
  };

  return (
    <NavLink className={'containerBtnSmall'} onClick={onClick} to={link} style={contenairButtonStyle}>
      <div className={`masterBtnSmall`} style={buttonStyle}>
        {!hideIcon && <img className="iconBtn" src={iconSrc} alt="" />}
        <p className={styles.text}>{text}</p>
      </div>
    </NavLink>
  );
};

export default ButtonSmall;
