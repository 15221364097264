
import styles from "./InputTextLarge-styles.css";

const InputTextLarge = ({ textPlaceHolder, value, onChange, typeError, hasError, name, type }) => {
  return (
    <form className="containerInput">
      <input
        type={type}
        name={name}
        placeholder={textPlaceHolder}
        value={value}
        onChange={onChange}
        id={textPlaceHolder}
        className="inputTextLarge"
      />
      {hasError && <div className="error">{typeError}</div>}
    </form>
  );
};

export default InputTextLarge;

