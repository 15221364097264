
import { useLocation } from 'react-router-dom'; // Assurez-vous que cette ligne est ajoutée
import { FunctionComponent, useCallback } from "react";
import styles from "./Home-styles.css";
import HeaderGrey from "Components/Headers/HeaderGrey/HeaderGrey";
import ButtonLarge from "Components/Buttons/ButtonLarge/ButtonLarge";


const Home = () => {

  const location = useLocation(); // Utilisez useLocation pour accéder à la chaîne de requête actuelle

  // Créez le lien d'inscription avec les paramètres de requête actuels
  const inscriptionLink = `/inscription${location.search}`;

  return (
    <div className="accueil">
      <HeaderGrey showLoginButton={true} showSignupButton={false} />
      <div className="containerHome">
        <div className="titreHome">
          <b className="geogamingLditeur">
            GeoGaming : l'éditeur d'aventures
          </b>
        </div>
        <div className="paragrapheHome">
          <div className="homeContainer">
            <p className="punchlineHome">
              GeoGaming transforme le monde en votre terrain de jeu.
            </p>
            <p className="punchlineHome">
              Professionnels et amateurs, cet éditeur est fait pour vous !
            </p>
            <p className="punchlineHome">
              Ecrivez vos histoires à travers balades et jeux de piste.
            </p>
            <p className="punchlineHome">
              Générez des revenus en rendant payant vos parcours.
            </p>
            <p className="punchlineHome">
              Commencez dès maintenant !
            </p>
          </div>
        </div>

        <ButtonLarge
          text="Commencer"
          color="var(--blanc)"
          hideBorder="false"
          hideIcon="false"
          link={inscriptionLink} // Passer le lien modifié avec les paramètres de requête
          backgroundColor="var(--blue-geogaming)" />

      </div>
      <div className="containerVideo">
        <iframe className="videoHome" src="https://www.youtube.com/embed/h-eEgIu78pA" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
      </div>

    </div>
  );
};

export default Home;