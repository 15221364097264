import React from 'react';
import styles from "./HeaderBlue-styles.css";
import LogoV2 from "../../Logo/LogoV2/LogoV2";
import MenuHorizontal from './MenuHorizontal/MenuHorizontal';
import MenuMonProfil from'./MenuMonProfil/MenuMonProfil';
import LinkComponent from '../../Buttons/Link/LinkComponent';

export default function HeaderBlue() {
  return (
    <div>
      <div className="headerBlue">
        <LogoV2 className="logoV2" link="/mesparcours" />
     
        <div className="containerMenueBlue">
          <ul className="itemContainer">
            <li className="item"><MenuHorizontal
              text="Mes Parcours"
              link="/mesparcours"/>
              </li>
            <li className="item"><MenuHorizontal
              text="Modèles d'aventures"
              link="https://edit.geo-gaming.com/login/SimpleScenario/ParcoursPreconfigures.php"/></li>
            <li className="item"><MenuHorizontal
              text="Tutoriels & boites à outil"
              link="https://geo-gaming.com/creer-un-jeu-de-piste-ou-un-escape-game-exterieur/"/></li>
            <li className="item"><MenuHorizontal
              text="Espace partenairess"
              link="/https://geo-gaming.com/partenaires/"/></li>
          </ul>
        </div>
        <div className="containerLinkFormulas"> 
          <LinkComponent
            text="Pourquoi prendre un forfait créateur ?"
            link="https://edit.geo-gaming.com/login/avantagesPremium.php"
            hideIcon="false"
          />
        </div>
        <MenuMonProfil/>
      </div>
    </div>
  );
}
